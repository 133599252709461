<template>
    <section class="profile-section">
        <div class="container">
            <div class="profile-content">
                <div class="profile-info">
                    <h1 class="profile-name">{{ name }}</h1>
                    <p class="profile-description">{{ description }}</p>
                    <div class="social-icons">
                        <a href="https://twitter.com/Xiljo8MNKQDKUTB" target="_blank" aria-label="X" class="social-icon">
                        <img src="@/assets/pictures/x.webp" alt="Twitter" class="social-icon-image">
                        </a>
                    </div>
                </div>
                    <div class="profile-image-wrapper">
                        <img :src="require(`@/assets/pictures/${profileImage}`)" alt="Profile Image" class="profile-image" />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
name: 'ProfileSection',
data() {
    return {
    name: 'ゆる先生',
    description: '個人開発をしている現役の小学校の先生。教育からAIを身近に。おうちの珈琲豆と日本酒係。教育心理 │ 学習科学 │ 教育工学 │ リフレクション │ メタ認知的支援 │ アフォーダンス',
    profileImage: '745_profile.webp',
    };
},
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.profile-section {
    padding: 40px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.profile-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ddd;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.profile-info {
    flex: 1;
    padding: 20px;
}

.profile-name {
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.profile-description {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    font-weight: 500;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: left;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-icon {
    display: inline-block;
    margin-top: 20px;
    border-radius: 50%;
    transition: color 0.3s, box-shadow 0.3s;
}

.social-icon-image {
    width: 50px; /* アイコンのサイズ */
    height: 50px; /* アイコンのサイズ */
}

.social-icon:hover {
  box-shadow: 0 0 10px 5px rgba(29, 161, 242, 0.5); /* 発光効果 */
}

.profile-image-wrapper {
    flex-shrink: 0;
    padding: 20px;
}

.profile-image {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .profile-content {
        flex-direction: column;
        text-align: center;
    }

    .profile-image-wrapper {
        padding: 0;
        margin-bottom: 20px;
    }

    .profile-image {
        margin: 0 auto;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
    }
}

</style>
