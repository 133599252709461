<template>
<div :class="['loading-animation', { hidden: loaded }]">
    <div class="loading-message" v-if="!loaded">
    <div class="loading-icon"></div>
    <p>Loading...</p>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const loaded = ref(false);

onMounted(() => {
// ページ読み込みが完了したら loaded を true に設定
window.addEventListener('load', () => {
    setTimeout(() => {
    loaded.value = true;
    }, 1500); // 2秒後にローディング画面を消す
});
});
</script>

<style scoped>

.loading-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 1s ease-in-out; /* スムーズなトランジション */
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

.loading-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff; /* ローディングメッセージの文字色を白に */
    animation: fadeIn 1.5s ease-in-out infinite; /* フェードインアニメーション */
}

@keyframes fadeIn {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.loading-icon {
    width: 50px;
    height: 50px;
    border: 5px solid #f0f0f0; /* アイコンの枠線を追加 */
    border-top: 5px solid #3498db; /* アニメーション用の色 */
    border-radius: 50%;
    margin-bottom: 10px;
    animation: spin 1s linear infinite; /* スピンアニメーション */
    
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

p {
    font-size: 1.2em; /* 文字のサイズを調整 */
    margin: 0;
}
</style>
