<template>
    <div class="advertisement">
        <div class="advertisement-container">
            <p class="hidden-text">もしよろしければ、このサイトをみんなにおすすめしてください！</p>
            <div class="advertisement-input-container">
                <!-- スクリーンリーダー用のラベル -->
                <label for="advertisement-textbox" class="visually-hidden">広告テキスト</label>
                <textarea 
                    id="advertisement-textbox"
                    :value="advertisementText"
                    class="advertisement-textbox"
                    readonly
                ></textarea>
                <div class="X-section">
                    <a :href="XLink" target="_blank" class="X-icon">
                        <img src="@/assets/pictures/x.webp" alt="X Icon" />
                    </a>
                    <button @click="copyAdvertisementText" class="copy-button">
                        {{ copySuccess ? 'Copied!' : 'Copy' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// eslint-disable-next-line
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            advertisementText: 'ゆるTools｜無料で使えるシンプルなAIツールコレクション @ゆるTools https://yurutools.com',
            copySuccess: false,
        };
    },
    methods: {
        copyAdvertisementText() {
            const textArea = document.createElement('textarea');
            textArea.value = this.advertisementText;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            this.showCopySuccess();
        },
        showCopySuccess() {
            this.copySuccess = true;
            setTimeout(() => {
                this.copySuccess = false;
            }, 2000);
        },
    },
    computed: {
        XLink() {
            return `https://twitter.com/intent/tweet?text=ゆるTools｜無料で使えるシンプルなAIツールコレクション+%40ゆるTools%0Ahttps%3A%2F%2Fyurutools.com`;
        },
    },
});
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.advertisement {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

/* スクリーンリーダーのみ向けのラベルを視覚的に隠すスタイル */
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/*-------------------------------------------
X広告
-------------------------------------------*/
.hidden-text {
    font-size: 1.2rem;
    white-space: nowrap; /* 1行に抑える */
}

.advertisement-container {
    text-align: center;
    margin-top: 20px;
}

.advertisement-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.advertisement-textbox {
    width: 90%;
    height: 90px;
    font-size: 1.2rem;
    margin: 10px 0 0 0;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    resize: none; /* フレキシブルなリサイズを無効化 */
    overflow: hidden; /* スクロールを無効化 */
}

.X-section {
    /*margin-left: 10px;*/
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.X-icon {
    cursor: pointer;
    margin-right: 4px;
}

.copy-button {
    background-color: #3d3d3d;
    color: white;
    border: none;
    font-size: 1.1rem;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.copy-button:hover {
    background-color: #45a049;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media (max-width: 768px) {
    .advertisement-textbox {
        width: 100%; /* 幅を100%に設定 */
        font-size: 0.8rem;
    }
    .hidden-text {
        display: none; /* モバイル向けに非表示にする */
    }
}

</style>
