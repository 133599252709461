<template>
  <div id ="app">
      <!--ParticleAnimation /-->
      <LoadingAnimation />
      <Main_NavBar/>
      <router-view></router-view>
      <AdSection />
      <FootBar />
  </div>
</template>

<script lang="ts">
import LoadingAnimation from './components/Base/LoadingAnimation.vue';
import Main_NavBar from '@/components/Base/Main_NavBar.vue';
import AdSection from '@/components/Base/AdSection.vue';
import FootBar from '@/components/Base/FootBar.vue';

//import ParticleAnimation from './components/Base/ParticleAnimation.vue';
export default {
  components: {
    LoadingAnimation,
    //ParticleAnimation,
    Main_NavBar,
    AdSection,
    FootBar
  }
}
</script>

<style>
#app {
  font-family: 'Helvetica', sans-serif;
  color: #333;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(135deg, #f5f5f5, #e0e0e0);
}

/* PC向けレイアウト */
@media (min-width: 769px) {
  #app {
    max-width: 1920px;
    min-width: 768px;
    margin: 0 auto;
    width: 100%;
  }
}

/* モバイル向けレイアウト */
@media (max-width: 768px) {
  #app {
    max-width: 100%;
  }
}
</style>


