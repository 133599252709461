<template>
    <div class="contact-page">
        <h2 class="contact-title">お問い合わせ</h2>
        <p class="contact-description">お気軽にご質問やご要望をお知らせください。</p>
        <!--img class="contact-image" src="993.webp" alt="お問い合わせ画像" /--> 
        <div class="media-notice">
            <h1 class="title">☑メディア掲載及び利用を許可しています</h1>
            <p class="description">ゆるToolsについての掲載(Webページに引用等)は自由に行っていただいて問題ありません。</p>
            <p class="description">ITやWebを活用した業務のご相談やメディア取材も受け付けております。お気軽にお問合せください。</p>
        </div>
        <!-- Google Formのリンク -->
        <a href="https://forms.gle/cf5N5d73aLvCDezi7" class="contact-link" target="_blank">
            お問い合わせフォームはこちら
        </a>
    </div>
</template>

<script lang="ts">
export default {
name: 'ContactPage',
};
</script>

<style scoped>
.contact-page {
    max-width: 70%;
    margin: auto;
    text-align: center;
    padding: 20px;
}

.contact-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
}


/*.contact-image {
    max-width: 60%;
    border-radius: 8px;
}*/

.contact-description {
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
}

.media-notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 40px;
    background-color: #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #333;
    margin-bottom: 16px;
}

.description {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: left;
    margin-bottom: 24px;
}

.contact-link {
    display: inline-block;
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.contact-link:hover {
    background-color: #45a049;
}


@media (max-width: 768px) {
    .contact-description {
        font-size: 0.9rem;
    }

    .media-notice {
        padding: 15px;
    }

    .title {
        font-size: 20px;
    }

    .description {
        font-size: 0.8rem;
    }

    .contact-link {
        padding: 10px 15px;
    }
}
</style>



