<template>
    <div class="transcription">
        <h1 class="main-title">音声文字起こしAI</h1>
        
        <!-- タブメニュー -->
        <div class="tab-menu">
            <button :class="{ active: activeTab === 'audio' }" @click="activeTab = 'audio'">音声ファイル</button>
            <button :class="{ active: activeTab === 'youtube' }" @click="activeTab = 'youtube'">Youtube文字起こし</button>
        </div>
        
        <!-- タブコンテンツ -->
        <div v-if="activeTab === 'audio'" class="tab-content">
            <input type="file" @change="onFileChange" accept="audio/*" class="audio-file-input">
            <button @click="transcribeAudio" :disabled="!audioFile" class="convert-button">変換</button>
        </div>
        <div v-if="activeTab === 'youtube'" class="tab-content">
            <input type="text" v-model="youtubeUrl" placeholder="https://" class="youtube-url-input">
            <button @click="transcribeYoutube" :disabled="!youtubeUrl" class="convert-button">変換</button>
        </div>
        
        <!-- ローディング画面 -->
        <div v-if="isLoading" class="loading">
            <p class="loading-text">{{ loadingText }}</p>
        </div>

        <!-- 変換結果 -->
        <div v-if="transcription" class="transcription-result">
            <h2>変換結果</h2>
            <p>{{ transcription }}</p>
                <div class="annotation-container">
                    <p>この文字起こしはにAIモデルによって生成されたものであり、原文の完全性や正確性を保証するものではありません。</p>
                </div>
                <button @click="copyTranscription" class="copy-button" aria-label="コピー">
                    {{ copySuccess ? 'コピーしました' : 'コピー' }}
                </button>
        </div>

        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="error-message">
            <div class="alert" role="alert" aria-live="assertive">
                {{ errorMessage }}
            </div>
        </div>

        <!-- モーダルウィンドウ -->
        <!--
        <div v-if="modalVisible" class="modal" @click="closeModal">
            <div class="modal-content" @click.stop>
            <div class="close-button" @click="closeModal" aria-label="閉じる">×</div>
                <div class="advertisement-container">
                    <p class="hidden-text">もしよろしければ、このサイトをみんなにおすすめしてください！</p>
                    <textarea v-model="advertisementText" class="advertisement-textbox" readonly aria-label="広告文"></textarea>
                    <div class="X-section">
                        <a :href="XLink" target="_blank" class="X-icon" aria-label="Xでシェア">
                            <img src="x.webp" alt="X Icon" />
                        </a>
                        <button @click="copyAdvertisementText" class="copy-button" aria-label="コピー">
                            {{ copySuccess ? 'コピーしました' : 'コピー' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        -->

    </div>
</template>

<script lang="ts">
import TranscriptionHandler from "@/service/TranscriptionHandler";
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
        activeTab: 'audio',
        audioFile: null as File | null,
        youtubeUrl: '',
        transcription: '',
        isLoading: false,
        loadingText: '',
        //modalVisible: false,
        //advertisementText: 'ゆるTools｜無料で使えるシンプルなAIツールコレクション @ゆるTools https://yurutools.com',
        copySuccess: false,
        errorMessage: '' as string
        };
    },
    methods: {
        onFileChange(event: Event) {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
                this.audioFile = target.files[0];
            }
        },

        async transcribeAudio() {
            if (!this.audioFile) return;
        
            this.isLoading = true;
            //this.modalVisible = true;

            const handler = new TranscriptionHandler();
            try {
            this.transcription = await handler.transcribeAudio(this.audioFile);
            } catch (error) {
                console.error('Error:', error);
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.isLoading = false;
            }
        },
        async transcribeYoutube() {
            if (!this.youtubeUrl) return;
        
            this.isLoading = true;
            //this.modalVisible = true;

            const handler = new TranscriptionHandler();
            try {
            this.transcription = await handler.getTranscriptFromYoutube(this.youtubeUrl);
            } catch (error) {
                console.error('Error:', error);
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.isLoading = false;
            }
        },
            startTypingEffect() {
            // ローディング画面でのタイピングエフェクトを開始する
            const loadingText = '文字起こし中...';
            let index = 0;
            setInterval(() => {
                this.loadingText = loadingText.slice(0, index + 1);
                index++;
                if (index === loadingText.length) index = 0;
            }, 400); // タイピング速度の調整
        },
            copyTranscription() {
                const textarea = document.createElement('textarea');
                textarea.value = this.transcription;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                this.showCopySuccess();
        },
            /*
            closeModal(): void {
                this.modalVisible = false;
        },
        */
            
            /*
            copyAdvertisementText() {
                const textArea = document.createElement('textarea');
                textArea.value = this.advertisementText;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                this.showCopySuccess();
            },
            */
            showCopySuccess() {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
            }, 2000);
        },
    },
    /*
    computed: {
        XLink() {
            return `https://twitter.com/intent/tweet?text=ゆるTools｜無料で使えるシンプルなAIツールコレクション+%40ゆるTools%0Ahttps%3A%2F%2Fyurutools.com`;
        },
    },
    */
    mounted() {
        this.startTypingEffect(); // コンポーネントがマウントされたときにタイピングエフェクトを開始する
    },
});
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}

.transcription {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.annotation-container {
    position: relative;
    padding: 20px;
    font-size: 10px;
}

.annotation-container p {
    text-align: center;
}


/*-------------------------------------------
タブメニュー
-------------------------------------------*/
.tab-menu {
    margin-bottom: 20px;
    font-size: 24px;
}

.tab-menu button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    background-color: #999999;
    cursor: pointer;
    font-size: inherit; /* タブメニューの文字サイズを継承する */
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.tab-menu button:hover {
    background-color: #ddd; /* ホバー時の背景色 */
    border-radius: 5px;
}

.tab-menu button.active {
    background-color: #ddd;
    border-radius: 5px;
    border-bottom: 3px solid green;
}

.tab-content {
    margin-bottom: 20px;
}

.audio-file-input, .youtube-url-input {
    margin-top: 10px;
    width: 90%;
    padding: 10px;
    font-size: 18px;
}

/*-------------------------------------------
変換ボタン
-------------------------------------------*/
.convert-button {
    margin-top: 20px;
    padding: 15px 30px;
    border: none;
    background-color: #343a40;
    color: white;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s;
    }

.convert-button:hover {
    background-color: #23272b;
}

.loading {
    margin-top: 20px;
}

.loading-text {
    font-size: 24px;
    animation: loadingAnimation 2s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

/*-------------------------------------------
出力結果
-------------------------------------------*/
.transcription-result {
    font-size: 18px;
    margin-top: 20px;
    position: relative;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

.transcription-result p {
    text-align: left;
}

.copy-button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.copy-button:hover {
    background-color: #0056b3;
}

/*-------------------------------------------
モーダルウィンドウ
-------------------------------------------
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 3;
}

.hidden-text {
    font-size: 1.2rem;
    color: #333;
    white-space: nowrap;
}

.close-button {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 2em;
    cursor: pointer;
}
*/

/*-------------------------------------------
モーダルウィンドウ+X広告
-------------------------------------------
.advertisement-container {
    text-align: center;
}

.advertisement-input-container {
    display: flex;
    align-items: center;
}

.advertisement-textbox {
    width: 90%;
    height: 90px;
    font-size: 1.2rem;
    margin: 10px 0 0 0;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    resize: none;
}

.X-section {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.X-icon {
    cursor: pointer;
    margin-right: 10px;
}
*/

.copy-button {
    background-color: #3d3d3d;
    color: white;
    border: none;
    font-size: 1.1rem;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #45a049;
}

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
        font-size: 2em;
        white-space: nowrap; /* 1行に抑える */
        align-items: baseline;
    }

    .tab-menu {
        font-size: 0.9rem;
    }

    .tab-content {
        font-size: 0.9rem;
    }

    .audio-file-input, .youtube-url-input {
        width: 100%;
    }

    .convert-button {
        position: relative;
        font-size: 1em;
        white-space: nowrap; /* 1行に抑える */
    }

    .transcription-result {
        font-size: 12px;
    }

    .transcription-result .annotation-container{
        font-size: 8px;
    }

    /*
    .modal-content {
        width: 80%;
        max-width: 80%;
        height: auto;
        max-height: 70%;
        overflow-y: auto;
    }

    .hidden-text {
        font-size: 0.6rem;
    }

    .advertisement-textbox {
        height: 60px;
        font-size: 0.8rem;
    }
    */
}

</style>
