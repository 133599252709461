<template>
    <div>
        <!--LoadingAnimation /-->
        <!--Main_NavBar/-->
        <WorksPage />
        <PdfdownloaderPage />
        <!--ContactPage /-->
        <!--FootBar /-->
    </div>
</template>

<script lang="ts">
//import LoadingAnimation from '@/components/LoadingAnimation.vue';
//import Main_NavBar from '@/components/Main_NavBar.vue'
import WorksPage from '@/components/Section/WorksPage.vue';
import PdfdownloaderPage from '@/components/Section/PdfDownloaderPage.vue';
//import ContactPage from '@/components/Section/ContactPage.vue'
//import FootBar from '@/components/FootBar.vue'
export default {
components: {
    //LoadingAnimation,
    //Main_NavBar,
    WorksPage,
    PdfdownloaderPage
    //ContactPage,
    //FootBar
    }
}
</script>
