import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_Main_NavBar = _resolveComponent("Main_NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AdSection = _resolveComponent("AdSection")!
  const _component_FootBar = _resolveComponent("FootBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoadingAnimation),
    _createVNode(_component_Main_NavBar),
    _createVNode(_component_router_view),
    _createVNode(_component_AdSection),
    _createVNode(_component_FootBar)
  ]))
}