<template>
  <div class="sentiment">
    <h1 class="main-title">感情分析AI</h1>

    <form @submit.prevent="analyze">
      <div class="form-item large-input">
        <label for="input" class="form-label">テストしたい文</label>
        <textarea id="input" v-model="form.input" autocomplete="off" placeholder="こちらに文章を入力してください。" style="font-size: 1.2rem;"></textarea>
      </div>
      <div class="form-footer">
        <button type="submit">解析</button>
      </div>
      <div class="form-item">
        <label class="form-label">↓分析結果が表示されます↓</label>
        <div class="readonly-text" placeholder="こちらに文章を入力してください。">{{ form.output }}</div>
      </div>
      
      <!-- エラーメッセージ -->
      <div v-if="errorMessage" class="error-message" role="alert">
        <div class="alert" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </form>

    <!-- モーダルウィンドウ -->
    <!--
    <div v-if="modalVisible" class="modal" @click="closeModal" aria-modal="true" role="dialog">
      <div class="modal-content" @click.stop>
        <div class="close-button" @click="closeModal" role="button" aria-label="Close modal">×</div>
        <div class="advertisement-container">
          <p class="hidden-text">もしよろしければ、このサイトをみんなにおすすめしてください！</p>
          <textarea v-model="advertisementText" class="advertisement-textbox" readonly></textarea>
          <div class="X-section">
            <a :href="XLink" target="_blank" class="X-icon">
              <img src="x.webp" alt="X Icon" />
            </a>
            <button @click="copyAdvertisementText" class="copy-button">
              {{ copySuccess ? 'コピーしました' : 'コピー' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  -->

  </div>
</template>

<script lang="ts">
import DataApiService from "@/service/DataApiService";
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const form = ref({
      input: '',
      output: '',
    });
    //const modalVisible = ref(false);
    //const advertisementText = ref('ゆるTools｜無料で使えるシンプルなAIツールコレクション @ゆるTools https://yurutools.com');
    //const XLink = 'https://twitter.com/intent/tweet?text=ゆるTools｜無料で使えるシンプルなAIツールコレクション+%40ゆるTools%0Ahttps%3A%2F%2Fyurutools.com';
    //const copySuccess = ref(false);
    const errorMessage = ref('');

    const analyze = () => {
      //modalVisible.value = true; // ボタンを押したらモーダルを表示

      DataApiService.create(form.value.input, form.value.output)
        .then(response => {
          form.value.output = response.data.output;
          //closeModal(); // 解析が完了したらモーダルを閉じる
        })
        .catch((error) => {
          console.error('Error:', error);
          errorMessage.value = '解析中にエラーが発生しました。もう一度更新してからお試しください。';
          //closeModal(); // エラーが発生したらモーダルを閉じる
        });
    };

    /*
    const closeModal = () => {
      modalVisible.value = false;
    };
    */

    /*
    const copyAdvertisementText = () => {
      const textArea = document.createElement('textarea');
      textArea.value = advertisementText.value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      copySuccess.value = true;
      setTimeout(() => {
        copySuccess.value = false;
      }, 2000);
    };
    */

    return {
      form,
      //modalVisible,
      //advertisementText,
      //XLink,
      //copySuccess,
      analyze,
      //closeModal,
      //copyAdvertisementText,
      errorMessage
    };
  },
});
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}

.sentiment {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.form-item {
    width: 100%;
    font-size: 1.2rem;
    margin: 10px 0;
    /*border: 1px solid #ddd;*/
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.form-label {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.large-input textarea {
    width: 100%;
    height: 150px;
    margin-top: 10px;
    resize: vertical;
}

.readonly-text {
    font-size: 1.2rem;
    padding: 20px;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    /*background-color: #f9f9f9;*/
    margin-top: 5px;
}

button[type="submit"] {
    background-color: #1eb300;
    border-color: #1eb300;
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: #1eb300bf;
}

/*-------------------------------------------
モーダルウィンドウ
-------------------------------------------
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 3;
}

.hidden-text {
    font-size: 1.2rem;
    color: #333;
    white-space: nowrap;
}

.close-button {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 2em;
    cursor: pointer;
}

.advertisement-container {
    text-align: center;
}

.advertisement-textbox {
    width: 90%;
    height: 90px;
    font-size: 1.2rem;
    margin: 10px 0 0 0;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    resize: none;
}

.X-section {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.X-icon {
    cursor: pointer;
    margin-right: 10px;
}

.copy-button {
    background-color: #3d3d3d;
    color: white;
    border: none;
    font-size: 1.1rem;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #45a049;
}
*/

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
      font-size: 2em;
      white-space: nowrap; /* 1行に抑える */
    }

    /*
    .modal-content {
        width: 80%;
        max-width: 80%;
        height: auto;
        max-height: 70%;
        overflow-y: auto;
    }

    .hidden-text {
      font-size: 0.6rem;
    }

    .advertisement-textbox {
        height: 60px;
        font-size: 0.8rem;
    }
    */
}

</style>
