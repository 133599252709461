<template>
    <div class="terms">
        <h1 class="title">利用規約</h1>
        <p class="right-text">
            制定日 : 2024年4月1日
        </p>
        <p class="text-separator"></p>
        <p>
            この利用規約（以下，「本規約」といいます。）は，ゆるTools（以下，「当サイト」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。
            ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
        </p>

        <section>
            <h2 class="section-title">第１条（規約の適用）</h2>
            <ol class="section-text">
                <li>
                    本規約は，ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
                </li>
                <li>
                    当サイトは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。
                    これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
                </li>
                <li>
                    本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
                </li>
            </ol>
        </section>

        <section>
            <h2 class="section-title">第２条（禁止事項）</h2>
            <p>
                ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
            </p>
                <ul class="section-text">
                    <li>
                        法令または公序良俗に違反する行為
                    </li>
                    <li>
                        犯罪行為に関する行為
                    </li>
                    <li>
                        本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
                    </li>
                    <li>
                        当サイト，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                    </li>
                    <li>
                        本サービスによって得られた情報を商業的に利用する行為
                    </li>
                    <li>
                        当サイトのサービスの運営を妨害するおそれのある行為
                    </li>
                    <li>
                        不正アクセスをし，またはこれを試みる行為
                    </li>
                    <li>
                        他のユーザーに関する個人情報等を収集または蓄積する行為
                    </li>
                    <li>
                        不正な目的を持って本サービスを利用する行為
                    </li>
                    <li>
                        本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
                    </li>
                    <li>
                        他のユーザーに成りすます行為
                    </li>
                    <li>
                        当サイトが許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
                    </li>
                    <li>
                        当サイトのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                    </li>
                    <li>
                        その他，当サイトが不適切と判断する行為
                    </li>
                </ul>
        </section>

        <section>
            <h2 class="section-title">第３条（本サービスの提供の停止等）</h2>
            <ol class="section-text">
                <li>
                    当サイトは，以下のいずれかの事由があると判断した場合，
                    ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                </li>
                    <ul class="section-text">
                        <li>
                        本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                        </li>
                        <li>
                        地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                        </li>
                        <li>
                        コンピュータまたは通信回線等が事故により停止した場合
                        </li>
                        <li>
                        その他，当サイトが本サービスの提供が困難と判断した場合
                        </li>
                    </ul>
                <li>
                    当サイトは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，
                    一切の責任を負わないものとします。
                </li>
            </ol>
        </section>

        <section>
            <h2 class="section-title">第４条（保証の否認および免責事項）</h2>
                <ol class="section-text">
                    <li>
                        当サイトは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，
                        セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                    </li>
                    <li>
                        当サイトは，本サービスに起因してユーザーに生じたあらゆる損害について、当サイトの故意又は重過失による場合を除き、一切の責任を負いません。
                    </li>
                    <li>
                        前項ただし書に定める場合であっても，当サイトは，当サイトの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害
                        （当サイトまたはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。
                    </li>
                    <li>
                        当サイトは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
                    </li>
                </ol>
        </section>

        <section>
            <h2 class="section-title">第５条（サービス内容の変更等）</h2>
                <p>
                    当サイトは，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
                </p>
        </section>

        <section>
            <h2 class="section-title">第６条（利用規約の変更）</h2>
                <p>
                    当サイトは以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。 
                </p>
                <ul class="section-text">
                    <li>
                        本規約の変更がユーザーの一般の利益に適合するとき。
                    </li>
                    <li>
                        本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
                    </li>
                </ul>

        </section>

        <section>
            <h2 class="section-title">第７条（個人情報の取扱い）</h2>
                <p>
                    当サイトは，本サービスの利用によって取得する個人情報については，当サイト「プライバシーポリシー」に従い適切に取り扱うものとします。
                </p>
        </section>

        <section>
            <h2 class="section-title">第８条（準拠法・裁判管轄）</h2>
            <ol class="section-text">
                <li>
                    本規約の解釈にあたっては，日本法を準拠法とします。
                </li>
                <li>
                    本サービスに関して紛争が生じた場合には，当サイトの本店所在地を管轄する裁判所を専属的合意管轄とします。
                </li>
            </ol>
        </section>

        <p class="text-separator"></p>
        <p class="right-text">
            以上
        </p>
    </div>
</template>

<style scoped>
.terms {
font-family: 'Arial', sans-serif;
max-width: 70%;
margin: 0 auto;
padding: 20px;
}

.title {
font-size: 2em;
text-align: center;
margin-bottom: 20px;
}

.section-title {
font-size: 1.5em;
margin-top: 20px;
margin-bottom: 10px;
}

.section-text {
line-height: 1.6;
padding-left: 20px;
}

.text-separator {
    margin: 20px;
}

.right-text{
    text-align: right;
}
</style>
