<template>
    <h2 class="catchphrase">About</h2>
    <p class="explain">「ゆるTools」は、無料で使いやすいシンプルなAIツールアプリです。</p>

    <section id="works" class="works">
        <div class="work-container">
            <div class="work" v-for="(scrape_work, index) in scrape_works" :key="index" @click="goToLink(scrape_work.link)">
                <img :src="require(`@/assets/pictures/${scrape_work.image}`)" :alt="scrape_work.title">
                <h3>{{ scrape_work.title }}</h3>
                <p>{{ scrape_work.description }}</p>
            </div>
        </div>
    </section>

    <section id="abouts" class="about">
        <div class="about-container">
            <div class="project" v-for="(scrape_project, index) in scrape_projects" :key="index">
                <img :src="require(`@/assets/pictures/${scrape_project.image}`)" :alt="scrape_project.title">
                <h3>{{ scrape_project.title }}</h3>
                <p>{{ scrape_project.description }}</p>
            </div>
        </div>
    </section>

    <section id="works" class="works">
        <div class="work-container">
            <div class="work" v-for="(transcript_work, index) in transcript_works" :key="index" @click="goToLink(transcript_work.link)">
                <img :src="require(`@/assets/pictures/${transcript_work.image}`)" :alt="transcript_work.title">
                <h3>{{ transcript_work.title }}</h3>
                <p>{{ transcript_work.description }}</p>
            </div>
        </div>
    </section>

    <section id="abouts" class="about">
        <div class="about-container">
            <div class="project" v-for="(transcript_project, index) in transcript_projects" :key="index">
                <img :src="require(`@/assets/pictures/${transcript_project.image}`)" :alt="transcript_project.title">
                <h3>{{ transcript_project.title }}</h3>
                <p>{{ transcript_project.description }}</p>
            </div>
        </div>
    </section>

    <section id="works" class="works">
        <div class="work-container">
            <div class="work" v-for="(work, index) in works" :key="index" @click="goToLink(work.link)">
                <img :src="require(`@/assets/pictures/${work.image}`)" :alt="work.title">
                <h3>{{ work.title }}</h3>
                <p>{{ work.description }}</p>
            </div>
        </div>
    </section>

    <section id="abouts" class="about">
        <div class="about-container">
            <div class="project" v-for="(project, index) in projects" :key="index">
                <img :src="require(`@/assets/pictures/${project.image}`)" :alt="project.title">
                <h3>{{ project.title }}</h3>
                <p>{{ project.description }}</p>
            </div>
        </div>
    </section>

    <section id="works" class="works">
        <div class="work-container">
            <div class="work" v-for="(chat_work, index) in chat_works" :key="index" @click="goToLink(chat_work.link)">
                <img :src="require(`@/assets/pictures/${chat_work.image}`)" :alt="chat_work.title">
                <h3>{{ chat_work.title }}</h3>
                <p>{{ chat_work.description }}</p>
            </div>
        </div>
    </section>

    <section id="abouts" class="about">
        <div class="about-container">
            <div class="project" v-for="(chat_project, index) in chat_projects" :key="index">
                <img :src="require(`@/assets/pictures/${chat_project.image}`)" :alt="chat_project.title">
                <h3>{{ chat_project.title }}</h3>
                <p>{{ chat_project.description }}</p>
            </div>
        </div>
    </section>

</template>
    
<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();

const scrape_works = [
    {
        id: 1,
        image: "1632.webp",
        title: 'PDF一括DL',
        description: 'サイト内にある複数のPDFを一度でダウンロードします',
        link: '/pdfdownloader'
    }
];

const transcript_works = [
    {
        id: 1,
        image: "955.webp",
        title: '音声文字起こし',
        description: '音声ファイルやYoutubeの音声を文字起こしします。',
        link: '/transcript'
    }
];

const chat_works = [
    {
        id: 1,
        image: '891.webp',
        title: 'タイトル提案',
        description: 'アップロードされたファイルの文章からファイル名を提案します。',
        link: '/generator',
    },
    {
        id: 2,
        image: '319.webp',
        title: '文章要約',
        description: 'アップロードされたファイルの文章を要約します。',
        link: '/summarizer'
    },
];

const works = [
    {
        id: 1,
        image: '1451.webp',
        title: '感情分析',
        description: '文章を入力したら、ポジティブさやネガティブさを判定します。通知表や毎日の日記のお供にどうぞ。',
        link: '/sentiment'
    }
];

const scrape_projects = [
    {
        id: 1,
        image: 'pdficon.webp',
        title: 'PDFだけを抽出',
        description: 'たくさんのpdfのあるURLから検索でき、ページ内のPDFファイルだけを表示します。'
    },
    {
        id: 2,
        image: 'folders.webp',
        title: 'まとめて入手する',
        description: '１回でたくさんの書類(pdf)をダウンロードすることができます。何度もクリックする必要がなくなります。'
    },
    {
        id: 3,
        image: "lockicon.webp",
        title: '正しく利用する',
        description: '取得が禁止されているサイトは、ダウンロードできないようにしています。'
    }    
];

const transcript_projects = [
    {
        id: 1,
        image: 'audiofile.webp',
        title: '様々な形式に対応',
        description: '音声ファイルやYoutubeのURLから読み取ることができます。'
    },
    {
        id: 2,
        image: 'transcription.webp',
        title: '文字起こしをする',
        description: '音声ファイルをAIモデルが高速で処理し、文字を起こします。何度も録音する必要がなくなります。'
    },
    {
        id: 3,
        image: "secure_browser.webp",
        title: '安全に使う',
        description: 'あなたの音声ファイルやAIモデルを、インターネット上に表示させずに処理します。'
    }    
];

const chat_projects = [
    {
        id: 1,
        image: 'upload.webp',
        title: '文書ファイルに対応',
        description: 'Word等のdocx形式とpdf形式のファイルを読み取ることができます。'
    },
    {
        id: 2,
        image: 'summary.webp',
        title: '概要をつかむ',
        description: '重要な情報を識別し、要約を作成して、文書の要点を把握します。もうページをめくったりスクロールしたりする必要がありません。'
    },
    {
        id: 3,
        image: "user_security.webp",
        title: 'AIを安全に使う',
        description: 'Google社のGemmaモデルを使用し、あなたの文書ファイルをインターネット上に表示させずに処理します。'
    }    
];

const projects = [
    {
        id: 1,
        image: 'world_icon.webp',
        title: '多言語に対応',
        description: 'あらゆる言語の文章を受け入れ、あらゆる言語で感情を分析します。'
    },
    {
        id: 2,
        image: 'decision.webp',
        title: '感情を判定する',
        description: '「ポジティブ」「ニュートラル」「ネガティブ」が何％かを、客観的に判定します。'
    },
    {
        id: 3,
        image: "Analyze.webp",
        title: '感情分析に特化したAI',
        description: '感情分析モデル「lxyuan/distilbert-base-multilingual-cased-sentiments-student」を使います。'
    }  
];

const goToLink = (link: any) => {
    router.push(link);
};

</script>
        
<style scoped>
.catchphrase {
    font-size: 4rem;
    text-align: center;
    margin: 40px 0px 40px 0px;
}

.explain {
    font-size: 1.2rem;
    text-align: center;
    margin: 40px 0px 40px 0px;
}

/*-------------------------------------------
Workセクションのスタイリング
-------------------------------------------*/
.works {
    max-width: 100%;
    padding: 20px 15%;
}

.work-container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work {
    flex: 0 0 calc(33.333% - 10px); /* 3つのプロジェクトを等間隔に配置 */
    text-align: center;
    max-width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.5s, opacity 0.5s;
}

.work img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.work h3 {
    font-size: 24px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.work p {
    font-size: 14px;
    margin-bottom: 10px;
}

.work:hover {
    transform: scale(0.95);
    opacity: 0.8;
}

/*-------------------------------------------
Aboutセクションのスタイリング
-------------------------------------------*/
.about {
    width: 70%;
}

.about-container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    flex: 0 0 calc(33.333% - 10px); /* 3つのプロジェクトを等間隔に配置 */
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.project img {
    max-width: 30%;
    height: auto;
    margin-bottom: 10px;
}

.project h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project p {
    font-size: 14px;
}

/* セクション間の余白 */
.works:not(:last-child),
.about:not(:last-child) {
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .work,
    .project {
        flex: 0 0 calc(50% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .work,
    .project {
        flex: 0 0 calc(100% - 10px);
    }
}

</style>
